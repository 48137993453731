@import "./../base";
@import "./../../slider-revolution/revolution/css/layers.css";
@import "./../../slider-revolution/revolution/css/navigation.css";
@import "./../../flexslider/flexslider.css";
i {
  color: $green;
  font-size: 15px;
  margin-right: 5px;
}
header{
  font-family: "OpenSansSemiBold", Helvetica, Arial, sans-serif;
  background-color: black;
  color: white;
  font-size: 11px;
  letter-spacing: 1px;
  text-transform: uppercase;
  nav{

    text-transform: uppercase;
    .nav-link{
      font-family: "OpenSansRegular", Helvetica, Arial, sans-serif;
      padding: 1.2rem 1rem;
      font-size:16px;
    }
    img{
      height: 50px;
    }
  }
}
.sliderFont{
  font-size: 3.2rem !important;
  line-height: 4rem !important;
  &.winter{
    text-shadow: 0 3px 1px rgba(0,0,0,0.8);
    background-color: rgba(0,0,0,0.2);
    padding: 15px !important;
    border-radius: 5px;
  }
}
.headline{
  > div{
    background-color: $green !important;
    height: 2px !important;;
    width: 50px !important;
    display: inline-block !important;
  }
  > p {
      color: #999;
      font-size: 18px;
      display:block;
      margin: 0 auto;
  }
}
.sectionBg{
  background-color:#F9F9F9;
}
.highlight{
  margin-bottom:40px;
  i{
    font-size: 45px;
    position: absolute;
  }
  div{
    h6{
      font-family: "OpenSansSemiBold", serif
    }
    p{
      color: #999;
      font-size: 16px;
    }
  }
}

.block{
  position: relative;
  top:20px;
  height: 400px;
  width: 100%;
  background-color: white;
  box-shadow: 0 20px 25px -12px rgba(0, 0, 0, 0.09);
  &.big{
    height:440px;
    top: 0;
  }
  img{
    padding: 0 20px;
    width:100%;
  }
}
.imgWrapper{
  position: relative;
  box-shadow: 0 0 25px 10px rgba(0, 0, 0, 0.15);
  .slides > li > img{
    border: 8px solid white;
  }
  >div.imgInfo{
    background-color: rgba(128, 198, 65, 0.85);
    color: white;
    font-size: 14px;
    position: absolute;
    bottom: 8px;
    left: 8px;
    padding: 10px;
    border-top-right-radius: 10px;
    z-index: 4;
  }
  .flex-caption {
    position: absolute;
    top: 8px;
    right: 8px;
    background: rgba(255,165,0,0.85);
    padding: 10px;
    border-bottom-left-radius: 5px;
    color: white;
    font-size: 14px;
    box-shadow: 3px 1px 7px 1px rgba(0,0,0,0.2) inset;
  }
}
.wohnDesc{
  padding: 15px 20px;
  h6{
  font-family: "OpenSansSemiBold", serif;
    display:inline;
  }
  ul{
    max-width: 290px;
    > li {
    font-size: 15px;
    padding: 8px 4px;
    background-color: transparent !important;
      i {
        font-size: 12px;
        margin-right: 14px;
      }
    }
  }
}
button{
  background-color: transparent;
  transition: background-color 0.5s ease;
  border: 2px solid $green;
  border-radius: 20px;
  color: $green;
  font-size: 12px;
  padding: 7px 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
  i{
    color: $green;
    font-size: 17px;
    position: relative;
    right: -11px;
    top: 1px;
  }
  &:hover{
    background-color: $green;
    transition: background-color 0.5s ease;
    color: white;
    border-color: $green;
    i{
      color: white;
    }
    cursor:pointer;
  }
  &:active, &:focus, &:active:focus, &:focus {
    outline: none !important;
  }
}
.starDesc{
    font-size: 12px;
    color: #999;
}
.contacts{
  background-color: white;
  padding: 0;
  transition: background-color 0.5s ease;
  + .contacts > div {
    border-left: 1px solid #eee;
  }
  h4{
    font-family: "OpenSansSemiBold", serif;
    color:black;
  }
  i{
    font-size:45px;
  }
  p{
    color: #999;
    font-size: 14px;
  }
  &:hover{
    background-color: $green;
    transition: background-color 1s ease;
    p,i,h4 {
      color: white;
    }
  cursor:pointer;
  }
  a{
    text-decoration: none !important;
    outline: none;
  }
}
#formQuestion {
  label {
    font-size: 14px;
  }

  input {
    background-color: #F9F9F9;
    border: 2px solid #F9F9F9;
    padding: 10px;
    height: auto;
    &:checked{
      border: 2px solid $green;
      outline: 0 !important;
      box-shadow: none !important;
    }
    &:focus {
      border: 2px solid $green;
      outline: 0 !important;
      box-shadow: none !important;
    }
  }
  select {
    background-color: #F9F9F9;
    border: 2px solid #F9F9F9;
    padding: 10px;
    height: auto;

    &:focus {
      border: 2px solid $green;
      outline: 0 !important;
      box-shadow: none !important;
    }
  }
  textarea {
    background-color: #F9F9F9;
    border: 2px solid #F9F9F9;
    padding: 10px;
    height: auto;

    &:focus {
      border: 2px solid $green;
      outline: 0 !important;
      box-shadow: none !important;
    }
  }
}
footer {
  background-color: black;
  color: #bbb;
  font-size: 12px;
  letter-spacing: 0.2px;
  font-family: OpenSansSemiBold, Helvetica, Arial, serif;
  h3 {
    font-family: "OpenSansSemiBold", serif;
    color: white;
    text-transform: uppercase;
  }

  .bottomBar {
    font-size: 12px;
    color: #bbb;
    letter-spacing: 0.2px;
  }

  .container-fluid {
    background-color: rgba(255, 255, 255, 0.1);
  }
  ul.list-group {
    li {
      cursor: pointer;
      position: relative;
      top: -5px;
      margin: 0;
      padding: 5px 0;
      background-color: transparent;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2) !important;
      border-bottom : 0;
      list-style-type: none;
      i{
        position: absolute;
        right: 5px;
        top: 10px;
      }
    }
  }
}

a{
  color: #bbb;
  text-decoration: none;
  &:link {
    color: #bbb;
  }
  &:visited {
    color: #bbb;
  }
  &:hover {
    color: #bbb;
  }
  &:active {
    color: #bbb;
  }
}
#flexslider_2{
  position: relative;
  z-index: 3;
}
.flexslider{
  margin: 0 !important;
  background-color: transparent !important;
  border: 0 !important;
  border-radius: 0 !important;
}
.flex-control-paging li a{
  border: 1px solid $green;
  background-color: transparent !important;
  &.flex-active{
    background-color: $green!important;
  }
}
#sendQuestion{
  cursor: pointer;
  background-color: transparent;
  transition: background-color 0.5s ease;
  border: 2px solid $green;
  border-radius: 20px;
  color: $green;
  font-size: 12px;
  padding: 7px 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
  width:120px;
  margin: 0 auto;
  position: relative;
  div.spinner-border{
    display:none;
    width: 1rem;
    height: 1rem;
    position: absolute;
    color: #80c641;
    top: 7px;
    right: 10px;
  }
  i.fa-check-circle{
    display:none;
    position: absolute;
    font-size: 18px;
    right: 3px;
    top: 6px;
  }
  i.fa-times-circle{
    display:none;
    position: absolute;
    font-size: 18px;
    right: 3px;
    top: 6px;
  }
  i.fa-angle-right{
    position: relative;
    top: 1px;
    right: -10px;
  }
}

.social {
  i {
    color: #bbb;
    &.fa-facebook-f {
      font-size: 16px;
      top: -2px;
      position: relative;
    }
    &.fa-instagram {
      font-size:20px;
    }
  }
}
.flex-control-nav.flex-control-paging{
  z-index: 1 !important;
}